.input {
  width: 100%;
  height: 50px;
  font-family: inherit;
  font-size: 16px;
  padding: 0 12px;
  appearance: none;
  border: 1px solid var(--STROKE-Form-Normal);
  border-radius: 4px;
  background-color: var(--BG-Form-Dark);
  color: var(--TEXT-Base-Light-Primary);

  &::placeholder {
    color: var(--TEXT-Form-Placeholder);
  }

  &:focus {
    border-color: var(--STROKE-Form-Normal-Focus);
    border-width: 1px;
  }
}

.error {
  border-color: var(--STROKE-Form-Error);
  background-color: var(--BG-Form-Error);

  &:focus {
    border-color: var(--STROKE-Form-Error);
  }
}
