.btn {
  margin-top: 20px;
  width: 100%;
  font-size: 15px;
}

.link {
  margin-top: 32px;
  text-align: center;

  a {
    color: var(--TEXT-Base-Light-Primary);
    margin: 0 8px;
    font-weight: 700;
  }
}
