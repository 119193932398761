.alert{
  padding: 12px;
  font-size: 12px;
  background-color: var(--BG-Base-Dark-Tertiary);
  border-radius: 4px;
  line-height: 18px;

  @media (--more-than-tablet) {
    padding: 16px;
    font-size: 14px;
  }
}
