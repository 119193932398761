.form {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.formTitle {
  display: grid;
  gap: 4px 0;
  margin-bottom: 8px;
}

.formError {
  margin-top: 4px;
  color: #FF6B6B;
}
